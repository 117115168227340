import React from "react";

import classNames from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import featureStyle from "assets/jss/material-kit-react/views/landingPageSections/featureStyle.jsx";



class FeatureFormat extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer >
          <GridItem >
            <div className={classNames(classes.main, classes.mainRaised, classes.featureGrey)} >
                  <img 
                      src={require('pages/HomePage/img/homeFeature.png')}
                       className={classes.featureLeftImg}
                   />
            <div className={classes.featureRightInfo}>
                <h3>Format it HERE.</h3>
                <p>This will be a paragraph of stuff about Book Merlin</p>
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
} 

export default withStyles(featureStyle)(FeatureFormat);